<template>
  <div>
    <div>
      <FormulateInput
        v-model="involvements"
        type="group"
        name="involvements"
        validation="required"
        :validation-messages="{
            required: ''
        }"
        :repeatable="true"
        :add-label="addLabel"
        remove-position="after"
        :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-4 tw-items-end tw-w-full']"
        @repeatableRemoved="recalculateInvolvements(false)"
      >
        <template #remove="{ removeItem }">
          <button
            type="button"
            title="Verwijderen"
            class="tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger"
            @click="removeItem"
          >
            <i class="far fa-trash" />
          </button>
        </template>
        <div class="tw-flex tw-gap-x-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="poules"
            name="involvement"
            :validation-rules="{ selectedPouleIsNoReferrer }"
            :validation-messages="{
              required: 'Poule en percentage verplicht in te vullen',
              selectedPouleIsNoReferrer: 'Verdeling aanbrengers ligt vast.\nContact de helpdesk voor wijzigingen.'
            }"
            :params="{ poule_is_active: true, fully_owned: true }"
            outer-class="tw-my-0"
            validation="bail|required|selectedPouleIsNoReferrer"
            placeholder="Selecteer poule"
          />
          <FormulateInput
            type="number"
            min="1"
            max="100"
            name="percentage"
            outer-class="tw-my-0 tw-inline"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none  tw-w-[70px]']"
            :validation-rules="{ requiredTotalPercentage }"
            :validation-messages="{
              requiredTotalPercentage: `De som van de percentages moet gelijk zijn aan ${percentage(this.requiredPercentage)}`
            }"
            validation="bail|min:1|required|requiredTotalPercentage"
            placeholder="%"
            @input="recalculateInvolvements(false)"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-percent" />
            </div>
          </FormulateInput>
        </div>
      </FormulateInput>
    </div>
    <div v-if="requiredMessage" class="tw-text-error">
      {{ requiredMessage }}
    </div>
    <div
      v-if="records && records.length"
      :class="['tw-p-4 tw-rounded tw-shadow-lg tw-mb-4 tw-w-full', !isValidInput ? 'tw-opacity-30' : '']"
    >
      <div v-for="record in records" :key="record.office_id" class="tw-mb-4 last:tw-mb-0">
        <div class="tw-font-bold tw-mb-2">Kantoor {{ record.office_reference }} ({{ percentage(record.office_share) }})</div>
        <table  class="tw-table-fixed tw-w-full tw-border-0.5 tw-border-collapse">
          <thead>
            <tr class="tw-font-bold">
              <td class="tw-p-1 tw-border tw-pl-2">Poule</td>
              <td class="tw-p-1 tw-border tw-pl-2">Rol</td>
              <td class="tw-p-1 tw-border tw-pl-2">In</td>
              <td :class="['tw-p-1 tw-border tw-pl-2', context === 'dossierPreparation' ? 'tw-text-gray-300' : '']">
                Uit <span v-if="context === 'dossierPreparation'">(*)</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="poule in record.poules" :key="poule.poule_id">
              <td class="tw-p-1 tw-border tw-pl-2">{{ poule.poule_name }}</td>
              <td class="tw-p-1 tw-border tw-pl-2">{{ getRole(poule.role) }}</td>
              <td class="tw-p-1 tw-border tw-pl-2">{{ percentage(poule.in) }}</td>
              <td :class="['tw-p-1 tw-border tw-pl-2', context === 'dossierPreparation' ? 'tw-text-gray-300' : '']">
                {{ percentage(poule.out) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-if="context === 'dossierPreparation' && records && records.length">
      (*) In de dossieropbouwfase worden de uit-percentages gelijk verdeeld over de verschillende inkopers
      om zodoende de kantoorverdeling correct te kunnen berekenen en weer te geven. De uit-percentages worden niet opgeslagen
      en kunnen worden ingegeven bij de dossierafhandeling.
    </p>
  </div>
</template>

<script>
import { percentage } from '@/utils/helpers'
import { calculateTransactionInvolvementData } from '@/services/transactions'
export default {
  name: 'TransactionInvolvementData',
  props: {
    propertyId: {
      type: [String, Number],
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      buyers: [],
      records: [],
      involvements: [],
      requiredPercentage: null,
      isValidInput: true,
      referrerPouleIds: []
    }
  },
  computed: {
    role () {
      return this.context === 'dossierPreparation' ? 'buyer' : 'seller'
    },
    addLabel () {
      if (this.role === 'buyer') return '+ Inkoper'
      if (this.context === 'salesAgreement') return '+ Verkopende poule'
      return '+ Verhurende poule'
    },
    requiredMessage () {
      if (this.involvements.length) return ''
      return 'Selecteer minstens één poule'
    }
  },
  created () {
    this.init()
  },
  methods: {
    percentage,
    async init () {
      const response = await this.recalculateInvolvements(true)
      this.setInvolvements()
      // The referrer poule IDs do not change
      // Set them once to use in the form validation
      this.setReferrerPouleIds()
      return response
    },
    getRole (role) {
      const roleMap = {
        buyer: 'Inkoper',
        referrer: 'Aanbrenger',
        seller: 'Verkoper',
        'buyer + seller': 'Inkoper + verkoper'
      }
      return roleMap[role]
    },
    requiredTotalPercentage () {
      if (!this.requiredPercentage) return true
      const sumOfIn = this.involvements.reduce((total, record) => total + parseFloat(record.percentage), 0)
      return sumOfIn === this.requiredPercentage
    },
    selectedPouleIsNoReferrer (value) {
      return !this.referrerPouleIds.includes(value.value?.id)
    },
    setInvolvements () {
      const involvements = this.records
        .flatMap(office => office.poules)
        .filter(poule => [this.role, 'buyer + seller'].includes(poule.role))
      const result = involvements.map(involvement => {
        const percentage = this.context === 'dossierPreparation' ? involvement.in : involvement.out
        return { involvement: involvement, percentage: percentage }
      })
      this.involvements = result
    },
    setReferrerPouleIds () {
      const referrerPouleIds = this.records
        .flatMap(office => office.poules)
        .filter(poule => poule.role === 'referrer')
        .map(poule => poule.id)
      this.referrerPouleIds = referrerPouleIds
    },
    getInvolvements () {
      return this.involvements.map(involvement => {
        return {
          poule_id: involvement.involvement.id,
          role: this.role,
          percentage: involvement.percentage
        }
      })
    },
    async recalculateInvolvements (init = false) {
      if (!this.requiredTotalPercentage()) {
        this.isValidInput = false
        return
      } else {
        this.isValidInput = true
      }
      const payload = {
        property_id: this.propertyId,
        type: this.context === 'dossierPreparation' ? 'involvements_table' : 'transaction'
      }
      if (!init) {
        payload.form_records = this.getInvolvements()
      }
      const response = await calculateTransactionInvolvementData(payload)
      this.records = response.data
      if (this.requiredPercentage === null) {
        const referrerExists = this.records.some(office =>
          office.poules.some(poule => poule.role === 'referrer')
        )
        this.requiredPercentage = referrerExists && this.role === 'buyer' ? 50 : 100
      }
      return response
    }
  }
}
</script>
